import { CSSProperties } from "react";
import Show from "./Show";
import { render } from "@testing-library/react";

const BlockFrame: CSSProperties = {
    position: 'absolute',
    top: 18,
    bottom: 8,
    borderRadius: 8,
    overflow: 'hidden'
}

interface ShowData {
    name: string,
    length: number,
    start: {
        hour: number,
        minute: number
    }
}

interface BlockProperties {
    name: string,
    width: number,
    color: string,
    start: {
        hour: number,
        minute: number
    },
    length: number,
    shows: Array<ShowData>
}

const Block = ({name, width, color, start, length, shows}:BlockProperties) => {
    const renderShows = (isSplit: boolean, isEnd: boolean) => {
        let renderedShows = new Array();
        const clonedShows: Array<ShowData> = [...shows];
        if (isSplit) {
            if (isEnd) {
                let timeLeft = 24 - (start.hour + (start.minute / 60));
                clonedShows.forEach(show => {
                    if (timeLeft > 0) {
                        if(show.length <= timeLeft) {
                            renderedShows.push(show);
                            timeLeft -= show.length;
                        }
                    }
                });
            } else {

            }
        } else {
            renderedShows = shows;
        }

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: 8,
                height: '100%'
            }}>
                {renderedShows.map(show => (<Show name={show.name} length={renderedShows.length > 1 ? show.length : 1.0} color={color}/>))}
            </div>
        )
    }
    const totalHours = Math.abs(length / 60);
    const extraHours = Math.abs(start.minute % 60) / 60;
    const isSplit = (start.hour + totalHours) >= 24;
    if(isSplit) {
        const minutesLeft = (start.minute / 60);
        const leftEndOver = 24 - (start.hour + minutesLeft);
        const leftEndTotal = (width * start.hour) + (minutesLeft * width);
        const startEndTotal = totalHours - leftEndOver;
        return(
            <>
                <div style={{...BlockFrame, ...{
                    left: leftEndTotal,
                    width: (width * leftEndOver),
                    background: `${color}44`,
                }}}>
                    <span style={{
                        color: color,
                        padding: 8,
                        fontSize: 12,
                        height: '100%',
                        margin: '0 auto',
                        fontWeight: 'bold',
                        marginLeft: 4,
                        textWrap: 'nowrap'
                    }}>
                        
                    </span>
                    {/*renderShows(isSplit, true)*/}
                </div>
                <div style={{...BlockFrame, ...{
                    left: 0,
                    width: (width * startEndTotal),
                    background: `${color}44`
                }}}>
                    <span style={{
                    color: color,
                    padding: 8,
                    fontSize: 12,
                    height: '100%',
                    margin: '0 auto',
                    fontWeight: 'bold',
                    marginLeft: 4,
                    textWrap: 'nowrap'
                    }}>
                        
                    </span>
                    {/*renderShows(isSplit, false)*/}
                </div>
            </>
        )
    } else {
        return(
            <div style={{...BlockFrame, ...{
                left:  (width * start.hour) + (extraHours * width),
                width: width * totalHours,
                background: `${color}44`,
                overflowY: 'hidden'
            }}}>
                <span style={{
                    color: color,
                    padding: 8,
                    fontSize: 12,
                    height: '100%',
                    margin: '0 auto',
                    fontWeight: 'bold',
                    marginLeft: 4
                }}>
                    {name}
                </span>
                {renderShows(isSplit, false)}
            </div>
        )
    }
}

export default Block;